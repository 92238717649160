<template>
	<div class="app-container corehumanStaff">
		<el-tabs v-model="activeName" class="cardtab" v-if="$route.name == 'CoreAudit'" @tab-click="tabChange">
			<el-tab-pane name="1" label="核心人力申报" v-if="permissionControl('CoreManpowerDeclaration')"></el-tab-pane>
			<el-tab-pane name="2" label="基本情况复查" v-if="permissionControl('ReviewBasicInformationByAppraval')"></el-tab-pane>
			<el-tab-pane name="3" label="核心人力调整" v-if="permissionControl('CoreManpowerAdjustmentByAppraval')"></el-tab-pane>
		</el-tabs>
		<el-tabs v-model="navActive" class="cardtab" v-if="$route.name == 'CoreReview'" @tab-click="tabChange1">
			<el-tab-pane name="2" label="基本情况复查" v-if="permissionControl('ReviewBasicInformationByReview')"></el-tab-pane>
			<el-tab-pane name="3" label="核心人力调整" v-if="permissionControl('CoreManpowerAdjustmentByReview')"></el-tab-pane>
		</el-tabs>
		<el-form :model="searchForm" ref="searchForm" inline class="searchForm" :style="$route.name == 'Statement' ? 'margin-top:0' : ''">
			<el-form-item label="年份">
				<el-date-picker
					v-model="searchForm.year"
					type="year"
					size="small"
					:clearable="false"
					placeholder="选择对应年份"
					value-format="yyyy"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="部门">
				<el-input v-model="searchForm.orgName" clearable size="small" placeholder="请输入部门名称"></el-input>
			</el-form-item>
			<el-form-item label="姓名">
				<el-input v-model="searchForm.name" size="small" clearable placeholder="请输入姓名"></el-input>
			</el-form-item>
			<el-form-item label="职位">
				<el-input v-model="searchForm.officeName" size="small" clearable placeholder="请输入职位名称"></el-input>
			</el-form-item>
			<el-form-item label="核心人力等级">
				<el-select v-model="searchForm.coreLevel" clearable placeholder="请选择核心人力等级" size="small">
					<el-option v-for="(item, i) in levels" :key="i" :label="item.levelName" :value="item.levelName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="状态" v-if="$route.name != 'CoreReview'">
				<el-select v-model="searchForm.approvalStatus" placeholder="请选择状态" size="small" clearable>
					<el-option v-for="(item, i) in statusArr" :key="i" :label="item.dictName" :value="item.dictId"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="入职时间" style="margin-right: 5px">
				<el-date-picker
					v-model="searchForm.entryTimeStart"
					:picker-options="pickerOptionsStart"
					clearable
					type="date"
					size="small"
					value-format="yyyy-MM-dd"
					placeholder="选择日期时间"
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.entryTimeEnd"
					:picker-options="pickerOptionsStart"
					clearable
					type="date"
					size="small"
					value-format="yyyy-MM-dd"
					placeholder="选择日期时间"
				></el-date-picker>
			</el-form-item>
			<el-form-item label-width="0px" label=" ">
				<el-button type="primary" @click="search" size="small" icon="el-icon-search">查询</el-button>
			</el-form-item>
			<!--<el-form-item label="生成时间" v-if="$route.name!='CoreReview'">
        <el-date-picker
          v-model="searchForm.createTimeArr"
          clearable
          :picker-options="pickerOptionsStart"
          type="datetimerange"
          size="small"
          style="width:340px"
          value-format="yyyy-MM-dd HH:MM:ss"
          :default-time="['00:00:00','23:59:59']"
          placeholder="选择日期时间"
        ></el-date-picker>
      </el-form-item>!-->
			<el-form-item :style="show && $route.name == 'CoreAudit' ? 'float:right;margin:-2px 0 10px 0px' : 'float:right;margin-right:0'">
				<el-button
					type="primary"
					@click="exportList"
					size="small"
					v-show="$route.name == 'Statement'"
					class="applyBtn"
					icon="el-icon-download"
					v-if="permissionControlBtns(pageName, 'Export')"
					>导出</el-button
				>
				<el-button
					v-if="show && $route.name == 'CoreAudit' && $store.state.app.staffId && permissionControlBtns(pageName, 'Declare')"
					type="primary"
					icon="el-icon-edit-outline"
					size="small"
					class="applyBtn"
					@click="report"
					>申报核心人力</el-button
				>
			</el-form-item>
		</el-form>
		<el-table :data="tableData" stripe v-loading="loading" v-if="$route.name == 'CoreAudit'" header-row-class-name="tableClass">
			<el-table-column label="序号" type="index" align="center"></el-table-column>
			<el-table-column
				v-for="col in columns"
				:prop="col.id"
				align="center"
				:key="col.id"
				:label="col.label"
				:width="col.width"
			></el-table-column>
			<el-table-column label="操作" align="center" min-width="120">
				<template slot-scope="{ row }">
					<el-button
						type="text"
						v-if="row.approvalStatus == 0 || (row.approvalStatus == 3 && permissionControlBtns(pageName, 'Edit'))"
						@click="checkDetail('1', row, 'edit')"
						>编辑</el-button
					>
					<el-button type="text" @click="Detail('', row, true)" v-if="permissionControlBtns(pageName, 'Detail')">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-table :data="reviewData" stripe v-loading="loading" v-else-if="$route.name == 'CoreReview'" header-row-class-name="tableClass">
			<el-table-column label="序号" type="index" align="center"></el-table-column>
			<el-table-column
				v-for="col in reviews"
				:prop="col.id"
				align="center"
				:key="col.id"
				:label="col.label"
				:width="col.width"
			></el-table-column>
			<el-table-column label="操作" align="center" min-width="120">
				<template slot-scope="{ row }">
					<el-button
						type="text"
						v-if="navActive == 2 && row.reviewStatus == 0 && $store.state.app.staffId && permissionControlBtns(pageName, 'Check')"
						@click="checkDetail('0', row, 'check')"
						>检查</el-button
					>
					<el-button
						type="text"
						v-if="
							navActive == 2 &&
							row.reviewStatus == '1' &&
							row.dataStatus == '1' &&
							$store.state.app.staffId &&
							permissionControlBtns(pageName, 'Edit')
						"
						@click="checkDetail('1', row, 'check')"
						>编辑</el-button
					>
					<el-button
						type="text"
						v-if="navActive == 3 && row.changeStatus == 0 && $store.state.app.staffId && permissionControlBtns(pageName, 'Change')"
						@click="checkDetail('0', row)"
						>调整</el-button
					>
					<el-button type="text" @click="Detail('', row, true)" v-if="permissionControlBtns(pageName, 'Detail')">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-table :data="statementData" stripe v-loading="loading" v-else-if="$route.name == 'Statement'" header-row-class-name="tableClass">
			<el-table-column label="序号" type="index" align="center"></el-table-column>
			<el-table-column
				v-for="col in statement"
				:prop="col.id"
				align="center"
				:key="col.id"
				:label="col.label"
				:width="col.width"
			></el-table-column>
			<el-table-column label="入职时间" prop="entryTime" align="center"></el-table-column>
			<el-table-column label="生成时间" prop="year" align="center"></el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="{ row }">
					<el-button type="text" @click="reportInfo(row)" v-if="permissionControlBtns(pageName, 'Detail')">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			style="float: right; margin: 20px 0"
			@size-change="sizeChange"
			@current-change="currentChange"
			:current-page="table.pageNo"
			:page-sizes="[20, 50, 80, 100, 200]"
			:page-size="table.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="table.totalNum"
		></el-pagination>
	</div>
</template>

<script>
// import treeselect from "@/components/treeSelect/treeSelect";
import { coreApprovalList, coreConfiglList, coreReportList, coreRivewList, getNearlyInfo } from '../../api/corehuman';
// import {timestampToTime} from "../../utils/util"
export default {
	components: {},
	data() {
		return {
			pageName: '',
			activeName: '1',
			navActive: '2',
			loading: false,
			items: [{ label: '核心人力审批' }, { label: '核心人力复查' }, { label: '核心人力报表' }, { label: '申报等级配置' }],
			searchForm: {
				orgName: '',
				year: '',
				name: '',
				officeName: '',
				coreLevel: '',
				approvalStatus: '',
				entryTimeStart: '',
				entryTimeEnd: '',
				createTimeArr: []
			},
			show: false,
			table: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() + 86400 > new Date(new Date().toLocaleDateString()).getTime();
				}
			},
			levels: [],
			statusArr: [],
			tableData: [],
			reviewData: [],
			statementData: [],
			columns: [
				{ id: 'name', label: '姓名' },
				{ id: 'phoneNumber', label: '手机号码', width: '110' },
				{ id: 'jobNumber', label: '工号' },
				{ id: 'orgName', label: '部门' },
				{ id: 'officeName', label: '职位' },
				{ id: 'latelyPerformance', label: '最近绩效' },
				{ id: 'entryTime', label: '入职时间' },
				{ id: 'coreLevel', label: '申请等级' },
				{ id: 'approvalStatusName', label: '状态' },
				{ id: 'createTime', label: '生成时间' }
			],
			reviews: [
				{ id: 'name', label: '姓名' },
				{ id: 'phoneNumber', label: '手机号码', width: '110' },
				{ id: 'jobNumber', label: '工号' },
				{ id: 'orgName', label: '部门' },
				{ id: 'officeName', label: '职位' },
				{ id: 'latelyPerformance', label: '最近绩效' },
				{ id: 'coreLevel', label: '核心人力等级', width: '110' },
				{ id: 'entryTime', label: '入职时间' },
				{ id: 'year', label: '生成年份' }
			],
			statement: [
				{ id: 'name', label: '姓名' },
				{ id: 'phoneNumber', label: '手机号码', width: '120' },
				{ id: 'jobNumber', label: '工号' },
				{ id: 'orgName', label: '部门' },
				{ id: 'officeName', label: '岗位名称' },
				{ id: 'latelyPerformance', label: '最近绩效', width: '120' },
				{ id: 'coreLevel', label: '核心人力等级', width: '110' },
				{ id: 'approvalStatusName', label: '状态' }
			]
		};
	},
	mounted() {
		if (this.$route.name === 'CoreAudit') {
			this.pageName = 'CoreManpowerDeclaration';
		}
		if (this.$route.name === 'CoreReview') {
			this.pageName = 'ReviewBasicInformationByAppraval';
		}
		this.statusArr = this.$store.state.app.dict.filter((item) => item.groupId == 'coreApprovalStatusMain');
		getNearlyInfo({}).then((res) => {
			if (res._responseStatusCode == 0) {
				delete res._responseStatusCode;
				this.show = res.length > 0;
			}
		});
		coreConfiglList({}).then((res) => {
			if (res._responseStatusCode == 0) {
				this.levels = res.configListDtos;
			}
		});
		const params = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		if (params && params.activeName && this.$route.name == 'CoreAudit') {
			this.activeName = params.activeName;
			this.pageName =
				this.activeName === '1'
					? 'CoreManpowerDeclaration'
					: this.activeName === '2'
					? 'ReviewBasicInformationByAppraval'
					: this.activeName === '3'
					? 'CoreManpowerAdjustmentByAppraval'
					: '';
		}
		if (params && params.searchForm) {
			this.searchForm = params.searchForm;
		}
		// if(this.$route.name=='CoreAudit'&&this.activeName!='1'){
		// this.searchForm.year=String(new Date().getFullYear()-1);
		// }else if(this.$route.name=='CoreAudit'&&this.activeName=='1'||this.$route.name=='CoreReview'){
		if (!params || !params.searchForm.year) {
			this.searchForm.year = String(new Date().getFullYear());
		}
		// }
		if (params && params.navActive && this.$route.name == 'CoreReview') {
			this.navActive = params.navActive;
			this.pageName =
				this.navActive === '2' ? 'ReviewBasicInformationByReview' : this.navActive === '3' ? 'CoreManpowerAdjustmentByReview' : '';
		}
		if (this.$route.name == 'Statement') {
			this.pageName = this.$route.name;
			this.getReport();
		} else if (this.$route.name == 'CoreReview') {
			this.getReivewList();
		} else {
			this.getData();
		}
		let list = [
			{ name: '1', key: 'CoreManpowerDeclaration' },
			{ name: '2', key: 'ReviewBasicInformationByAppraval' },
			{ name: '3', key: 'CoreManpowerAdjustmentByAppraval' }
		];

		if (this.$route.name === 'CoreReview') {
			list = [
				{ name: '2', key: 'ReviewBasicInformationByReview' },
				{ name: '3', key: 'CoreManpowerAdjustmentByReview' }
			];
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, list);
	},
	beforeRouteLeave(form, to, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				searchForm: this.searchForm,
				activeName: this.activeName,
				navActive: this.navActive
			})
		);
		next();
	},
	methods: {
		getReport() {
			const params = {
				...this.searchForm,
				createTimeStart: this.searchForm.createTimeArr == null ? '' : this.searchForm.createTimeArr[0],
				createTimeEnd: this.searchForm.createTimeArr == null ? '' : this.searchForm.createTimeArr[1],
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize
			};

			this.loading = true;
			coreReportList(params).then((res) => {
				if (res._responseStatusCode == 0) {
					this.statementData = res.list;
					// eslint-disable-next-line no-unused-expressions
					res.list[0] &&
						this.statement.map((it1) => {
							if (it1.id == 'latelyPerformance') {
								it1.label = `${res.list[0].year}年度绩效`;
							}
						});
					this.loading = false;
					this.table.totalNum = res.totalNum;
				}
			});
		},
		tabChange(e) {
			this.pageName =
				e.index === '1'
					? 'CoreManpowerDeclaration'
					: e.index === '2'
					? 'ReviewBasicInformationByAppraval'
					: e.index === '3'
					? 'CoreManpowerAdjustmentByAppraval'
					: '';
			// if(this.$route.name=='CoreAudit'&&this.activeName!='2'){
			//   this.searchForm.year=String(new Date().getFullYear()-1);
			// }else if(this.$route.name=='CoreAudit'&&this.activeName=='2'||this.$route.name=='CoreReview'){
			this.searchForm.year = String(new Date().getFullYear());
			// }
			if (this.$route.name == 'Statement') {
				this.getReport();
			} else if (this.$route.name == 'CoreReview') {
				this.getReivewList();
			} else {
				this.getData();
			}
		},
		tabChange1(e) {
			this.pageName = e.index === '0' ? 'ReviewBasicInformationByAppraval' : e.index === '1' ? 'CoreManpowerAdjustmentByAppraval' : '';
		},
		getReivewList() {
			const params = {
				...this.searchForm,
				type: this.navActive,
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize
			};

			this.loading = true;
			coreRivewList(params).then((res) => {
				if (res._responseStatusCode == 0) {
					this.reviewData = res.list;
					this.table.totalNum = res.totalNum;
					this.loading = false;
				}
			});
		},
		exportList() {
			const params = {
				orgName: this.searchForm.orgName,
				name: this.searchForm.staffName,
				year: this.searchForm.year,
				officeName: this.searchForm.officeName,
				coreLevel: this.searchForm.coreLevel,
				approvalStatus: this.searchForm.approvalStatus,
				entryTimeStart: this.searchForm.entryTimeStart,
				entryTimeEnd: this.searchForm.entryTimeEnd,
				createTimeStart: this.searchForm.createTimeArr == null ? '' : this.searchForm.createTimeArr[0],
				createTimeEnd: this.searchForm.createTimeArr == null ? '' : this.searchForm.createTimeArr[1]
			};

			const url = `${location.origin}/v1/core/report/list/export?params=${encodeURIComponent(JSON.stringify(params))}`;

			window.open(url);
		},
		report() {
			this.$router.push({ name: 'CoreApply' });
		},
		search() {
			if (this.$route.name == 'Statement') {
				this.getReport();
			} else if (this.$route.name == 'CoreReview') {
				this.getReivewList();
			} else {
				this.getData();
			}
		},
		Detail(type, row, isinfo) {
			if (this.$route.name == 'CoreAudit') {
				if (this.activeName == '1') {
					this.$router.push({
						name: 'applyInfo',
						params: { coreManpowerApprovalId: row.coreManpowerApprovalId },
						query: { isinfo, showApproval: isinfo === true }
					});
				} else if (this.activeName == '2') {
					this.$router.push({
						name: 'checkInfo',
						params: { coreManpowerApprovalId: row.coreManpowerApprovalId },
						query: { isinfo, approvalStatus: row.approvalStatus, showApproval: isinfo === true }
					});
				} else if (this.activeName == '3') {
					this.$router.push({
						name: 'corehumanAdjust',
						params: { coreManpowerApprovalId: row.coreManpowerApprovalId },
						query: { isinfo, showApproval: row.approvalStatus != 0 && row.approvalStatus != 3 }
					});
				}
			} else if (this.$route.name == 'CoreReview') {
				if (this.navActive == '2') {
					this.$router.push({
						name: 'checkInfo',
						params: { coreManpowerApprovalId: row.coreManpowerApprovalId },
						query: { isinfo, type, coreManpowerId: row.coreManpowerId, showApproval: isinfo === true }
					});
				} else {
					this.$router.push({
						name: 'corehumanAdjust',
						params: { coreManpowerApprovalId: row.coreManpowerApprovalId },
						query: { type, isinfo, coreManpowerId: row.coreManpowerId, showApproval: isinfo === true }
					});
				}
			}
		},
		checkDetail(type, row, isinfo) {
			this.Detail(type, row, isinfo);
		},
		reportInfo(row) {
			this.$router.push({ name: 'reportInfo', params: { coreManpowerApprovalId: row.coreManpowerApprovalId }, query: { isinfo: true } });
		},
		getData() {
			const params = {
				...this.searchForm,
				approvalType: this.activeName,
				entryTimeStart: this.searchForm.entryTimeArr == null ? '' : this.searchForm.entryTimeArr[0],
				entryTimeEnd: this.searchForm.entryTimeArr == null ? '' : this.searchForm.entryTimeArr[1],
				createTimeStart: this.searchForm.entryTimeArr == null ? '' : this.searchForm.entryTimeArr[0],
				createTimeEnd: this.searchForm.entryTimeArr == null ? '' : this.searchForm.entryTimeArr[1],
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize
			};

			this.loading = true;
			coreApprovalList(params).then((res) => {
				if (res._responseStatusCode == 0) {
					this.tableData = res.list;
					this.table.totalNum = res.totalNum;
					this.loading = false;
				}
			});
		},
		sizeChange(val) {
			this.table.pageSize = val;
			this.getData();
		},
		currentChange(val) {
			this.table.pageNo = val;
			this.getData();
		}
	}
};
</script>

<style lang="scss">
.corehumanStaff {
	overflow: hidden;
	position: relative;
	label {
		font-weight: normal;
	}
	.el-input__suffix {
		height: 32px;
	}
	.searchForm {
		margin-top: 40px;
		.el-date-editor,
		.el-input,
		.el-select {
			width: 200px;
		}
	}
	.reportCore {
		position: absolute;
		top: 8%;
		right: 2%;
	}
	.toptabs .el-tabs__item {
		font-size: 15px;
		font-weight: 600;
	}
	.cardtab {
		margin: 10px 0 0;
		height: 10px;
		.el-tabs__header {
			border-bottom: none;
		}
		.el-tabs__item {
			border-bottom: 1px solid #e4e7ed !important;
		}
	}
}
</style>
<style lang="scss" scoped>
@media (min-width: 1220px) {
	.mediaSearch {
		float: right;
		margin-top: 4px;
		margin-bottom: 0px;
		margin-right: 0;
	}
}
@media (min-width: 1300px) {
	.mediaSearch {
		margin-bottom: 10px;
		// transform: translateY(-10px);
	}
}
</style>
